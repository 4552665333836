import "./App.css";
import Landing from "./pages/Landing/Landing";

function App() {
	return (
		<>
			<Landing />
		</>
	);
}

export default App;
