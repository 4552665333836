import React, { useEffect, useRef, useState } from "react";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import "./Taara.css";
import InstagramImage1 from "../../images/Instagram1.png";
import InstagramImage2 from "../../images/Instagram2.png";
import InstagramImage3 from "../../images/Instagram3.png";

import AskTaaraForm from "../../components/AskTaaraForm/AskTaaraForm";

const Taara = () => {
	const sectionRefs = {
		TaaraFirstIntro: useRef(null),
		TaaraFaceArea: useRef(null),
		TaaraOneLiners: useRef(null),
		TaaraReason: useRef(null),
		TaaraSocial: useRef(null),
		TaaraThoughts: useRef(null),
		TaaraDescription: useRef(null),
		TaaraStoryShare: useRef(null),
		TaaraMissionArea: useRef(null),
		WhyTaaraCreatedArea: useRef(null),
		AskTaaraArea: useRef(null),
	};

	useEffect(() => {
		const observerOptions = {
			root: null,
			rootMargin: "0px",
			threshold: 0.5, // Trigger when 50% of the element is visible
		};

		const handleIntersection = (entries, observer) => {
			entries.forEach((entry) => {
				if (entry.isIntersecting) {
					entry.target.classList.add("fade-in");
					entry.target.classList.remove("fade-out"); // Ensure fade-out is removed
				} else {
					entry.target.classList.remove("fade-in");
					entry.target.classList.add("fade-out"); // Optionally, add fade-out for exit animation
				}
			});
		};

		const observer = new IntersectionObserver(handleIntersection, observerOptions);

		// Observe all sections
		Object.values(sectionRefs).forEach((ref) => {
			if (ref.current) {
				observer.observe(ref.current);
			}
		});

		return () => {
			// Cleanup observer on unmount
			observer.disconnect();
		};
	}, []);

	const InstagramCards = [
		{
			image: InstagramImage1,
		},
		{
			image: InstagramImage2,
		},
		{
			image: InstagramImage3,
		},
	];
	return (
		<>
			<Navbar />
			<div className="TaaraContainer">
				<div className="TaaraIntroImage">
					<div className="TaaraIntroLeftImage"></div>
					<div className="TaaraIntroRightImage">I'M NOT A HERO</div>
				</div>
				<div className="TaaraFirstIntro" ref={sectionRefs.TaaraFirstIntro}>
					<div className="TaaraFirstIntroTopText">
						That's the first thing that you <br />
						should know about me, I didn't
						<br /> get into this thinking I would
						<br />
						become a hero,
					</div>
					<div className="TaaraFirstIntroBottomText">
						Let alone have a video game
						<br />
						named after me?
					</div>
				</div>
				<div className="TaaraFaceArea" ref={sectionRefs.TaaraFaceArea}>
					I was a woman who got a job
					<br />
					<span>and then I started noticing</span>
				</div>
				<div className="TaaraOneLiners" ref={sectionRefs.TaaraOneLiners}>
					<span>The interruptions.</span>
					<span>The condescension.</span>
					<span>The blatant discrimination.</span>
				</div>
				<div className="TaaraReason" ref={sectionRefs.TaaraReason}>
					And like so many other women out
					<br />
					there, I decided I'd had enough of it.
					<span>And that's how Taara Quest was born.</span>
				</div>
				<div className="TaaraSocial" ref={sectionRefs.TaaraSocial}>
					<div className="TaaraSocialHeading">TAARA ON INSTAGRAM</div>
					<div className="TaaraSocialCards">
						{InstagramCards.map((instaCard) => {
							return (
								<>
									<div className="InstagramCard">
										<img src={instaCard.image} height={550} width={450} />
									</div>
								</>
							);
						})}
					</div>
					<a href="https://www.instagram.com/taara.aibigsister/" className="TaaraSocialButton">
						Follow Taara
					</a>
				</div>
				<div className="TaaraThoughts" ref={sectionRefs.TaaraThoughts}>
					Now I Know What You're Thinking
					<span>
						But Taara, if you're a kick-ass-system-
						<br />
						destroying-sexism-defeating-warrior-woman
						<br />
						how come you're just an influencer now??
					</span>
				</div>
				<div className="TaaraDescription" ref={sectionRefs.TaaraDescription}>
					<div className="TaaraLeftDescription"></div>
					<div className="TaaraRightDescription">
						<span>
							First off - I'm not<b>*just*</b> anything. No one is.
						</span>
						<span>
							But, I'm not only here to guide you through
							<br />
							your quest...
						</span>
						<span>
							I'm here to share the stories of every
							<br /> woman who has experienced the BS of the
							<br />
							corporate world.
						</span>
						<span>Harnessing power of media lets me do that.</span>
					</div>
				</div>
				<div className="TaaraStoryShare" ref={sectionRefs.TaaraStoryShare}>
					So, Let's Share Our Stories...<span>And Then We'll Rewrite Them</span>
				</div>
				<div className="TaaraIntroductionVideoTitle">Now, For a Proper Introduction - Watch This!</div>
				<video
					className="TaaraIntroVideoArea"
					src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Taara+Demo+Video+1.mp4"
					controls
					poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Taara+Introduction+Video+Thumbnail_Taara_Page_first+video.png"
				></video>
				<div className="TaaraMissionArea" ref={sectionRefs.TaaraMissionArea}>
					<div className="TaaraMissionLeft">
						THE MISSION<span>behind Taara</span>
					</div>
					<div className="TaaraMissionRight">
						Creating Taara isn't just about putting out
						<br />
						another AI Influencer for us at Taara Quest.
						<br />
						<br />
						It's about using technology to amplify the <br />
						challenges and voices of women!
						<br />
						<br />
						<span>
							Taara isn't just an experiment; she's a<br />
							revolution. She's the stubborn symbol of
							<br />
							purpose and calling we call carry within us.
						</span>
					</div>
				</div>
				<div className="WhyTaaraCreatedArea" ref={sectionRefs.WhyTaaraCreatedArea}>
					<div className="WhyTaaraCreatedTitle">
						Want to know more about how
						<br />
						and why I was created?
					</div>
					<video
						className="WhyTaaraCreatedVideo"
						src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Final_Making_of_Taara.mp4"
						controls
						poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/The+Making+Of+Video+Thumbnail_Taara_Page_second+video.png"
					></video>
				</div>
				<AskTaaraForm ref={sectionRefs.AskTaaraArea} />

				<Footer />
			</div>
		</>
	);
};

export default Taara;
