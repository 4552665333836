import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Taara from "./pages/Taara/Taara";
import About from "./pages/About/About";

const router = createBrowserRouter([
	{
		path: "/",
		element: <App />,
	},
	{
		path: "/taara",
		element: <Taara />,
	},
	{
		path: "/about",
		element: <About />,
	},
]);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<RouterProvider router={router} />);
