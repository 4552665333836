import React, { useEffect, useState } from "react";
import "./LaunchCountdown.css";
import moment from 'moment';
import 'moment-timezone';

const LaunchCountdown = ({ action }) => {
	const calculateTimeLeft = () => {
		const targetDateTime = moment.tz("2024-10-01 08:00", "Europe/Paris"); // Adjust for desired CEST location
		const now = moment.tz();
		const difference = targetDateTime.diff(now);
		let timeLeft;

		if (difference > 0) {
			timeLeft = {
				days: String(Math.floor(difference / (1000 * 60 * 60 * 24))).padStart(2, "0"),
				hours: String(Math.floor((difference / (1000 * 60 * 60)) % 24)).padStart(2, "0"),
				minutes: String(Math.floor((difference / 1000 / 60) % 60)).padStart(2, "0"),
				seconds: String(Math.floor((difference / 1000) % 60)).padStart(2, "0"),
			};
		}

		return timeLeft;
	};

	const [timeToLaunch, setTimeToLaunch] = useState(calculateTimeLeft());

	useEffect(() => {
		const timer = setInterval(() => {
			setTimeToLaunch(calculateTimeLeft());
		}, 1000);
	}, []);

	const timerComponents = [];

	Object.keys(timeToLaunch).forEach((interval) => {
		if (!timeToLaunch[interval]) {
			return;
		}

		timerComponents.push(
			<span key={interval}>
				{timeToLaunch[interval]} {interval}{" "}
			</span>
		);
	});

	return (
		<>
			<div className="launchCountdownArea">
				<div className="launchCountdownTitle">Launch Countdown</div>
				<div className="launchCountdownTimer">
					{Object.keys(timeToLaunch).length ? (
						`${timeToLaunch.days}:${timeToLaunch.hours}:${timeToLaunch.minutes}:${timeToLaunch.seconds}`
					) : (
						<span>00:00:00:00</span>
					)}
				</div>
				<div className="launchCountdownButton" onClick={action}>Join the waitlist</div>
			</div>
		</>
	);
};

export default LaunchCountdown;
