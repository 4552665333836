import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import "./Navbar.css";

const Navbar = ({ signupAction }) => {
	const navigate = useNavigate();
	const location = useLocation();
	const [open, setOpen] = useState(false);
	const [buttonClassName, setButtonClassname] = useState("navbarMenuDropdownClosed");

	const handleClick = () => {
		setOpen((value) => !value);
	};

	useEffect(() => {
		if (open) {
			setButtonClassname("navbarMenuDropdown");
		} else {
			setButtonClassname("navbarMenuDropdownClosed");
		}
	}, [open]);

	const handleNavigation = () => {
		if (location.pathname === "/taara") {
			localStorage.setItem("shouldScrollToSignup", true);
			navigate("/");
		} else {
			signupAction();
		}
	};

	return (
		<>
			<div className="navbarContainer">
				<div
					className="navbarLogo"
					onClick={() => {
						navigate("/");
					}}
				></div>
				<div className="navbarLinks">
					<div
						className="navbarLink"
						onClick={() => {
							navigate("/taara");
						}}
					>
						Taara
					</div>
					<div className="navbarLink" onClick={() => {
						navigate("/about");
					}}>About Us</div>
				</div>
				<div className="accountButton" onClick={handleNavigation}>
					Sign Up
				</div>
			</div>
			<div className="mobileNavbarContainer">
				<div
					className="navbarLogo"
					onClick={() => {
						navigate("/");
					}}
				></div>
				<div className="navBarMenuContainer">
					<div className="signUpButton" onClick={signupAction}>
						Sign Up
					</div>
				</div>
			</div>
		</>
	);
};

export default Navbar;
