import React from "react";
import "./TestimonialCard.css";

const TestimonialCard = ({ profile }) => {
	return (
		<div className="profileCard">
			<div className="leftProfileCard">
				<img src={profile.avatar} alt={profile.name} />
			</div>
			<div className="rightProfileCard">
				<div className="profileCardTitle">{profile.name}</div>
				<div className="profileCardDescription">{profile.text}</div>
				<div className="profileCardDesignation">{profile.designation}</div>
			</div>
		</div>
	);
};

export default TestimonialCard;
