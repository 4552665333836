import React from "react";
import AskTaaraForm from "../../components/AskTaaraForm/AskTaaraForm";
import Footer from "../../components/Footer/Footer";
import Navbar from "../../components/Navbar/Navbar";
import "./About.css";

const About = () => {
	const profiles = [
		{ name: "Kamilla Faszylova", imageUrl: "../../images/about/1.png" },
		{ name: "Charlie Sambo", imageUrl: "../../images/about/team/2.png" },
		{ name: "Bakhtawar Chaudary", imageUrl: "../../images/about/team/3.png" },
		{ name: "Shiza Durrani", imageUrl: "../../images/about/team/4.png" },
		{ name: "Athanasia Symeonidou", imageUrl: "../../images/about/team/5.png" },
		{ name: "Aisyah Taqwim", imageUrl: "../../images/about/team/6.png" },
	];

	return (
		<>
			<Navbar />
			<div className="AboutContainer">
				<div className="FirstSection">
					<div className="FirstSectionText">
						We're on a mission to help women <br />
						bring their light into the world
						<br />
						<span>undimmed.</span>
					</div>
				</div>
				<div className="SecondSection">
					<div className="SecondSectionText">
						The current systems make
						<br /> us sad and hold us back
					</div>
				</div>
				<div className="ThirdSection">
					<div className="ThirdSectionLeftElement">
						<div className="ThirdSectionLeftElementHeader">
							REDEFINING
							<br />
							LEADERSHIP
						</div>
						<span>
							Taara Quest helps women lead authentically at any stage of life by breaking barriers,
							offering innovative tech tools, and building a strong community.
						</span>
					</div>
					<div className="ThirdSectionRightElement"></div>
				</div>
				<div className="FourthSection">
					<div className="FourthSectionLeftElement"></div>
					<div className="FourthSectionRightElement">
						<div className="FourthSectionRightElementHeader">
							IMMERSIVE
							<br /> UNIVERSE
						</div>
						<span>
							Our immersive hub and global community allow women to get unstuck, show up confidently, and
							speak fearlessly.
						</span>
					</div>
				</div>
				<div className="FifthSection">
					<div className="FifthSectionTopText">Taara Quest began with one idea:</div>
					<div className="FifthSectionBottomText">
						To use AI and gaming
						<br />
						technology as a force to
						<br />
						supercharge women's growth.
					</div>
				</div>
				<div className="SixthSection">
					MEET THE MINDS
					<br />
					<span>behind the mission</span>
				</div>
				<div className="SeventhSection">
					<div className="SeventhSectionImageElement"></div>
					<div className="SeventhSectionTextElement">
						<div className="SeventhSectionTextElementHeader">Dr. Luise Frohberg</div>
						<div className="SeventhSectionTextElementContent">
							Luise is a former political leader and women leadership advocate. As a serial-entrepreneur,
							published author, and innovation expert she founded Taara Quest in 2021 after joining her
							sons in gaming worlds like Minecraft during the Covid-19-lockdown.
						</div>
					</div>
				</div>
				<div className="EightSection">
					<div className="profile-grid">
						<div className="profile-card kamilla">
							<p>
								Kamilla Faszylova
								<br />
								<span>Head of Marketing</span>
							</p>
						</div>
						<div className="profile-card charlie">
							<p>
								Charlie Sambo
								<br />
								<span>Community Manager</span>
							</p>
						</div>
						<div className="profile-card bakhtawar">
							<p>
								Bakhtawar Chaudary
								<br />
								<span>Trainee Marketing & Content Management</span>
							</p>
						</div>
						<div className="profile-card shiza">
							<p>
								Shiza Durrani
								<br />
								<span>Data Management</span>
							</p>
						</div>
						<div className="profile-card athnasia">
							<p>
								Athanasia Symeonidou
								<br />
								<span>Advisor XR Technology</span>
							</p>
						</div>
						<div className="profile-card aisyah">
							<p>
								Aisyah Taqwim
								<br />
								<span>Trainee Community Building</span>
							</p>
						</div>
					</div>
				</div>
				<div className="NinthSection">
					JOIN OUR JOURNEY
					<br />
					<span>at Taara Quest</span>
				</div>
				<div className="TenthSection">
					<div className="LeftTenthSection"></div>
					<div className="rightTenthSection">
						<p>
							Dive into a world of innovation and teamwork! We're looking for passionate individuals for
							internships and talented professionals for open roles
						</p>
						<span>Interested in partnering? We'd love to hear from you!</span>
					</div>
				</div>
				<AskTaaraForm />
				<Footer />
			</div>
		</>
	);
};

export default About;
