import React, { useEffect, useRef, useState } from "react";
import LaunchCountdown from "../../components/LaunchCountdown/LaunchCountdown";
import Navbar from "../../components/Navbar/Navbar";
import "./Landing.css";

import roundLogo from "../../images/round-logo.svg";

import TestimonialCarousel from "../../components/TestimonialCarousel/TestimonialCarousel";
import { useNavigate } from "react-router-dom";
import Footer from "../../components/Footer/Footer";

import ReactCardFlip from "react-card-flip";

import logo1 from "../../images/partners/1.png";
import logo2 from "../../images/partners/2.png";
import logo3 from "../../images/partners/3.png";
import logo4 from "../../images/partners/4.png";
import logo5 from "../../images/partners/5.png";
import logo6 from "../../images/partners/6.png";
import { encryptData } from "../../utility/encryption";
import axios from "axios";

const Landing = () => {
	const navigate = useNavigate();
	const testimonialSignupRef = useRef(null);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [password, setPassword] = useState("");
	const [errorMessage, setErrorMessage] = useState("");

	const [isLoading, setIsLoading] = useState(false);
	const [reactCardState, setReactCardState] = useState({
		quest1: {
			title: "CONFIDENCE & SELF-WORTH",
			decribeText: "Discover what's holding you back and learn to grow your confidence and self-worth.",
			flipped: false,
		},
		quest2: {
			title: "EFFECTIVE FEEDBACK",
			decribeText: "Learn how to give and receive feedback effectively without feeling troubled.",
			flipped: false,
		},
		quest3: {
			title: "NEGOTIATING PAY",
			decribeText: "Master the art of discussing money and confidently ask for more.",
			flipped: false,
		},
	});

	const handleSignup = () => {
		if (isLoading || name === "" || email === "") {
			return;
		} else {
			setIsLoading(true);
			setErrorMessage("");
			const URL = `${process.env.REACT_APP_BACKEND}/api/waitlist/join`;
			const payload = {
				name,
				email,
			};

			const token = encryptData(JSON.stringify(payload));
			axios
				.post(URL, { token })
				.then((response) => {
					setErrorMessage(response.data.message);
					console.log(errorMessage);
					setIsLoading(false);
					setName("");
					setEmail("");
					setPassword("");
				})
				.catch((error) => {
					setErrorMessage(error.response.data.message);
					console.log(errorMessage);
					setIsLoading(false);
					setName("");
					setEmail("");
					setPassword("");
				});
		}
	};

	const scrollToTestimonialSignup = () => {
		if (testimonialSignupRef.current) {
			testimonialSignupRef.current.scrollIntoView({ behavior: "smooth" });
		}
	};

	useEffect(() => {
		const shouldScrollToSignup = localStorage.getItem("shouldScrollToSignup");
		if (shouldScrollToSignup) {
			localStorage.removeItem("shouldScrollToSignup");
			scrollToTestimonialSignup();
		} else {
			window.scrollTo({ top: 0, behavior: "smooth" });
		}
	}, []);

	useEffect(() => {
		setErrorMessage("");
	}, []);

	const toggleFlip = (cardKey) => {
		setReactCardState((prevState) => ({
			...prevState,
			[cardKey]: {
				...prevState[cardKey],
				flipped: !prevState[cardKey].flipped,
			},
		}));
	};

	return (
		<>
			<div className="landingPageContainer">
				<Navbar signupAction={scrollToTestimonialSignup} />
				<div className="landingPageComponents">
					<div className="videoOverlayContainer">
						<video
							src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/landing-page-video-1.mp4"
							className="waitlistArea"
							autoPlay
							loop
							muted
						></video>
						<div className="overlayVideo">
							<div className="overlayVideoText">GROW INTO WHO YOU'RE MEANT TO BE.</div>
							<div className="overlayVideoButton" onClick={scrollToTestimonialSignup}>
								Join the Waitlist
							</div>
						</div>
					</div>
					<div className="brandBannerList">
						<div className="brandBanner">
							<img src={logo1}></img>
						</div>
						<div className="brandBanner">
							<img src={logo2}></img>
						</div>
						<div className="brandBanner">
							<img src={logo3}></img>
						</div>
						<div className="brandBanner">
							<img src={logo4}></img>
						</div>
						<div className="brandBanner">
							<img src={logo5}></img>
						</div>
						<div className="brandBanner">
							<img src={logo6}></img>
						</div>
					</div>
					<div className="secondWaitlistArea">
						<div className="secondWaitlistAreaContent">
							<span>Your</span> interactive space <span>to</span> practice (and mess up){" "}
							<span>those</span> real-world, hard-to-have
							<span> conversations before they happen.</span>
						</div>
						<div className="secondWaitlistAreaButton" onClick={scrollToTestimonialSignup}>
							Join the Waitlist
						</div>
					</div>
					<div className="graphicalBackgroundArea">
						<div className="topGraphicalArea">
							<div className="topGraphicalAreaText">
								YOU WONT BE ALONE <span>in your journey!</span>
							</div>
							<div className="topGraphicalAreaParagraph">
								With Taara's growth oriented feedback and encouragement, you'll unlearn self-doubt{" "}
								<span>quest-by-quest</span>
							</div>
						</div>
						<div className="bottomGraphicalArea">
							<div className="leftGraphicsArea">
								<div className="leftGraphicsAreaHeader">
									Through 6 immersive <br />
									roleplay quests, discover the <br /> layers of your potential
								</div>
								<div className="leftGraphicsAreaContent">
									<span>No Judgement.</span>
									<span>No Awkwardness.</span>
								</div>
								<div className="leftGraphicsAreaButton" onClick={scrollToTestimonialSignup}>
									Join The Waitlist
								</div>
							</div>
							<div className="rightGraphicsArea"></div>
						</div>
					</div>
					<LaunchCountdown action={scrollToTestimonialSignup} />
					<div className="unvelingArea">
						<div className="leftUnvelingArea">
							UNVEIL THE WORLD<span className="blackLeftUnvelingAreaText">of Taara Quest!</span>
						</div>
						<div className="rightUnveilingArea">
							<div className="rightUnveilingAreaText">
								Taara Quest is an innovative roleplaying game meticulously designed to arm women in the
								workplace with critical leadership skills, mindset shifting techniques, and actionable
								communication tools.
							</div>
						</div>
					</div>
					<div className="stepCards">
						<div
							className="stepCard"
							onMouseEnter={() => toggleFlip("quest1")}
							onMouseLeave={() => toggleFlip("quest1")}
						>
							<ReactCardFlip isFlipped={reactCardState.quest1.flipped} flipDirection="horizontal">
								<div className="frontCard">
									<div className="stepCardContainer">
										<div className="topStepCard">
											<div className="topStepCardIcon">
												<i className="fa-solid fa-circle-arrow-down"></i>
											</div>
										</div>

										<div className="bottomStepCard">
											<div className="bottomStepCardCount">Quest I</div>
											<div className="bottomStepCardTitle">{reactCardState.quest1.title}</div>
										</div>
									</div>
								</div>
								<div className="backCard backCardQuest1">
									<div className="stepCardContainerFlipped">
										<div className="topStepCard">
											<div className="topStepCardText">{reactCardState.quest1.decribeText}</div>
										</div>
									</div>
								</div>
							</ReactCardFlip>
						</div>
						<div
							className="stepCard"
							onMouseEnter={() => toggleFlip("quest2")}
							onMouseLeave={() => toggleFlip("quest2")}
						>
							<ReactCardFlip isFlipped={reactCardState.quest2.flipped} flipDirection="horizontal">
								<div className="frontCard">
									<div className="stepCardContainer">
										<div className="topStepCard">
											<div className="topStepCardIcon">
												<i className="fa-solid fa-circle-arrow-down"></i>
											</div>
										</div>
										<div className="bottomStepCard">
											<div className="bottomStepCardCount">Quest II</div>
											<div className="bottomStepCardTitle">{reactCardState.quest2.title}</div>
										</div>
									</div>
								</div>
								<div className="backCard backCardQuest2">
									<div className="stepCardContainerFlipped">
										<div className="topStepCard">
											<div className="topStepCardText">{reactCardState.quest2.decribeText}</div>
										</div>
									</div>
								</div>
							</ReactCardFlip>
						</div>
						<div
							className="stepCard"
							onMouseEnter={() => toggleFlip("quest3")}
							onMouseLeave={() => toggleFlip("quest3")}
						>
							<ReactCardFlip isFlipped={reactCardState.quest3.flipped} flipDirection="horizontal">
								<div className="frontCard">
									<div className="stepCardContainer">
										<div className="topStepCard">
											<div className="topStepCardIcon">
												<i className="fa-solid fa-circle-arrow-down"></i>
											</div>
										</div>
										<div className="bottomStepCard">
											<div className="bottomStepCardCount">Quest III</div>
											<div className="bottomStepCardTitle">{reactCardState.quest3.title}</div>
										</div>
									</div>
								</div>
								<div className="backCard backCardQuest3">
									<div className="stepCardContainerFlipped">
										<div className="topStepCard">
											<div className="topStepCardText">{reactCardState.quest3.decribeText}</div>
										</div>
									</div>
								</div>
							</ReactCardFlip>
						</div>
					</div>
					<div className="moreQuests">
						<div className="moreQuestsDiv">More Quests Coming Soon</div>
					</div>
					<div className="guideArea">
						<div className="guideAreaWrapper">
							<div className="leftGuideArea">
								<div className="leftGuideAreaTitle">
									<div className="leftGuideAreaTitleTop">MEET YOUR</div>
									<div className="leftGuideAreaTitleBottom">Guide Taara!</div>
								</div>
								<div className="rightGuideAreaForMobile"></div>
								<div className="leftGuideAreaContent">
									<p>
										Taara believes in the power of unity over division, compassion over control. Her
										mission transcends mere resistance against oppressive systems;
									</p>
									<p>It's about nurturing an environment where every individual can flourish.</p>
									<p>
										She imagines a world where workplaces become sancturies of opportunity, free
										from the shadows of inequality.
									</p>
								</div>
								<div
									className="leftGuideAreaButton"
									onClick={() => {
										navigate("/taara");
									}}
								>
									Learn More About Taara
								</div>
							</div>
						</div>
						<div className="rightGuideArea"></div>
					</div>
					<div className="taaraWorldArea">
						<div className="taaraWorldHeading">
							Taara's world Isn't Just About <span>Practicing Hard Conversations.</span> It's About...
						</div>
						<div className="taaraWorldFeatures">
							<div className="taaraWorldCard courageCard">
								<div className="taaraWorldCardIcon">
									<i className="fa-solid fa-person-rays"></i>
								</div>
								<div className="taaraWorldCardTitle">COURAGE</div>
								<div className="taaraWorldCardContent">
									Find the inner strength inside you to stand up and speak out.
								</div>
							</div>
							<div className="taaraWorldCard empathyCard">
								<div className="taaraWorldCardIcon">
									<i className="fa-solid fa-hand-holding-heart"></i>
								</div>
								<div className="taaraWorldCardTitle">EMPATHY</div>
								<div className="taaraWorldCardContent">
									Embrace having compassion for not just others -- but yourself, too.
								</div>
							</div>
							<div className="taaraWorldCard learningCard">
								<div className="taaraWorldCardIcon">
									<i className="fa-solid fa-graduation-cap"></i>
								</div>
								<div className="taaraWorldCardTitle">LEARNING</div>
								<div className="taaraWorldCardContent">
									Expand your professional toolkit with lessons in communication and confidence.
								</div>
							</div>
							<div className="taaraWorldCard communityCard">
								<div className="taaraWorldCardIcon">
									<i className="fa-solid fa-users"></i>
								</div>
								<div className="taaraWorldCardTitle">COMMUNITY</div>
								<div className="taaraWorldCardContent">
									Connect with women around the world who are on their leadership journey just like
									you
								</div>
							</div>
						</div>
					</div>
					<video
						className="videoContainer"
						src="https://taara-quest.s3.eu-central-1.amazonaws.com/videos/Platform+Intro+Video.mp4"
						controls
						poster="https://taara-quest.s3.eu-central-1.amazonaws.com/images/thumbnails/Product+Video+Thumbnail_Landingpage+second+video.png"
					></video>
					<LaunchCountdown action={scrollToTestimonialSignup} />
					<div className="testimonialSignup">
						<div className="testimonialContainer">
							<div className="testimonialHeading">
								WHAT PEOPLE ARE <br />
								<span>Saying About Taara Quest!</span>
							</div>
							<div className="TestimonialCarouselContainer">
								<TestimonialCarousel />
							</div>
						</div>
						<div className="signupContainer" ref={testimonialSignupRef}>
							<div className="taaraRoundLogo">
								<img src={roundLogo} alt="Taara Quest Logo" />
							</div>
							<h2>SIGN UP</h2>
							<div className="formComponents">
								<input
									type="text"
									placeholder="Full Name"
									value={name}
									onChange={(e) => {
										if (!isLoading) {
											setName(e.target.value);
										}
									}}
								/>
								<input
									type="email"
									placeholder="Email Address"
									value={email}
									onChange={(e) => {
										if (!isLoading) {
											setEmail(e.target.value);
										}
									}}
								/>
								{errorMessage ? (
									<>
										<div className="errorMessage">{errorMessage}</div>
									</>
								) : (
									<></>
								)}
								<button
									type="submit"
									onClick={() => {
										handleSignup();
									}}
								>
									Join the Waitlist
								</button>
							</div>
						</div>
					</div>
					<Footer />
				</div>
			</div>
		</>
	);
};

export default Landing;
